import React from "react";
import { useValidateBankFileMutation } from "../services/fbiAPI/fbiApiClient";

export const useUploadFileToURL: (bankFileId: number, uploadUrl: string) => {
    isSuccess: boolean,
    isLoading: boolean,
    isError: boolean,
    errorMessage: string | null,
    isValid: boolean,
    file?: File,
    setFile: (file?: File) => void,
    upload: () => Promise<void>,
} = (bankFileId: number, uploadUrl) => {
    const [file, setFile] = React.useState<File>();
    const [isError, setIsError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [validateBankFile] = useValidateBankFileMutation();
    const upload = async () => {
        if (file !== undefined) {
            setIsLoading(true);
            try {
                const validationResponse = await validateBankFile(file).unwrap();
                if (validationResponse.valid) {
                    try {
                        await fetch(
                            uploadUrl,
                            {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/octet-stream",
                                },
                                body: file,
                            }
                        )
                        setIsSuccess(true);
                    } catch (e) {
                        setIsError(true);
                        setErrorMessage('An error occurred during upload : ' + String(e));
                    }
                } else {
                    setIsError(true);
                    setErrorMessage('Incorrect bank file: ' + validationResponse.errors.join(', '));
                }
            } catch (e) {
                setIsError(true);
                setErrorMessage('An error occurred during validation of the bank file : ' + String(e));
            }
        }
    }
    return {
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        isValid: file !== undefined,
        file: file,
        setFile: setFile,
        upload: upload,
    }
}
