import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
    CashBalanceInquiryInvestigationDto,
    CashBalanceInquiryInvestigationStatus
} from "../../../services/fbiAPI/fbiApiClient";
import {Chip} from "@mui/material";
import Icon from "@mui/material/Icon";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel'
import Box from "@mui/material/Box";

const InvestigationStatus: React.FC<{ status: CashBalanceInquiryInvestigationStatus }> = ({status}) => {
    switch (status) {
        case "CREATED":
            return <Icon/>;
        case "QUEUED":
            return <HourglassBottomIcon
                color="warning"
                sx={{
                    animation: "spin 2s linear infinite",
                    "@keyframes spin": {
                        "100%": {
                            transform: "rotate(360deg)",
                        },
                        "0%": {
                            transform: "rotate(0deg)",
                        },
                    },
                }}
            />;
        case "RUNNING":
            return <AutorenewIcon
                color="primary"
                sx={{
                    animation: "spin 2s linear infinite",
                    "@keyframes spin": {
                        "100%": {
                            transform: "rotate(360deg)",
                        },
                        "0%": {
                            transform: "rotate(0deg)",
                        },
                    },
                }}
            />;
        case "SUCCESS":
            return <CheckCircleOutlineIcon color="success"/>;
        case "ERROR":
            return <ErrorOutlineIcon color="error"/>
        case "CANCEL":
            return <CancelIcon color="error"/>
        default:
            return <Icon/>;

    }

}

const PrimaryInvestigationListItem: React.FC<{
    investigation: CashBalanceInquiryInvestigationDto,
}> = ({investigation}) => {
    return <Box>{investigation.bank_file_name} - {investigation.bank_account_name}</Box>
}

const InvestigationListItem: React.FC<{
    investigation: CashBalanceInquiryInvestigationDto,
    onClick: () => void
}> = ({investigation, onClick}) => {
    return (
        <ListItem key={investigation.id} onClick={onClick}>
            <ListItemButton>
                <ListItemText
                    primary={<PrimaryInvestigationListItem investigation={investigation}/>}
                    secondary={investigation.created_at.toString()}
                />
                <Chip
                    label={investigation.status}
                    icon={<InvestigationStatus status={investigation.status}/>}
                    size="small"
                    variant="outlined"
                />
            </ListItemButton>
        </ListItem>
    );
}
export default InvestigationListItem
