import { _baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createBankFile: build.mutation<
      CreateBankFileApiResponse,
      CreateBankFileApiArg
    >({
      query: (queryArg) => ({
        url: `/bank-file`,
        method: "POST",
        body: queryArg.createBankFileDto,
      }),
    }),
    listBankFile: build.query<ListBankFileApiResponse, ListBankFileApiArg>({
      query: (queryArg) => ({
        url: `/bank-file`,
        params: {
          entity_id: queryArg.entityId,
          bank_account_name: queryArg.bankAccountName,
        },
      }),
    }),
    getBankFile: build.query<GetBankFileApiResponse, GetBankFileApiArg>({
      query: (queryArg) => ({ url: `/bank-file/${queryArg.bankFileId}` }),
    }),
    validateBankFile: build.mutation<ValidateBankFileApiResponse, ValidateBankFileApiArg>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: '/bank-file/validate',
          method: 'POST',
          body: formData,
        };
      },
    }),
    getUploadUrl: build.query<GetUploadUrlApiResponse, GetUploadUrlApiArg>({
      query: (queryArg) => ({
        url: `/bank-file/${queryArg.bankFileId}/upload-url`,
      }),
    }),
    createCashBalanceInquiryInvestigation: build.mutation<
      CreateCashBalanceInquiryInvestigationApiResponse,
      CreateCashBalanceInquiryInvestigationApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation`,
        method: "POST",
        body: queryArg.createCashBalanceInquiryInvestigationDto,
      }),
    }),
    listCashBalanceInquiryInvestigation: build.query<
      ListCashBalanceInquiryInvestigationApiResponse,
      ListCashBalanceInquiryInvestigationApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation`,
        params: {
          entity_id: queryArg.entityId,
          bank_file_id: queryArg.bankFileId,
        },
      }),
    }),
    getCashBalanceInquiryInvestigation: build.query<
      GetCashBalanceInquiryInvestigationApiResponse,
      GetCashBalanceInquiryInvestigationApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation/${queryArg.cashBalanceInquiryInvestigationId}`,
      }),
    }),
    getCashBalanceInquiryInvestigationResult: build.query<
      GetCashBalanceInquiryInvestigationResultApiResponse,
      GetCashBalanceInquiryInvestigationResultApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation/${queryArg.cashBalanceInquiryInvestigationId}/result`,
      }),
    }),
    resolveCashBalanceInquiryInvestigation: build.mutation<
      ResolveCashBalanceInquiryInvestigationApiResponse,
      ResolveCashBalanceInquiryInvestigationApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation/${queryArg.cashBalanceInquiryInvestigationId}/resolve`,
        method: "POST",
      }),
    }),
    cancelCashBalanceInquiryInvestigation: build.mutation<
      CancelCashBalanceInquiryInvestigationApiResponse,
      CancelCashBalanceInquiryInvestigationApiArg
    >({
      query: (queryArg) => ({
        url: `/cash-balance-inquiry-investigation/${queryArg.cashBalanceInquiryInvestigationId}/cancel`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as fbiApiClient };
export type CreateBankFileApiResponse =
  /** status 200 Successful Response */ BankFileCreatedDto;
export type CreateBankFileApiArg = {
  createBankFileDto: CreateBankFileDto;
};
export type ListBankFileApiResponse =
  /** status 200 Successful Response */ BankFileDto[];
export type ListBankFileApiArg = {
  entityId?: number | null;
  bankAccountName?: string | null;
};
export type GetBankFileApiResponse =
  /** status 200 Successful Response */ BankFileDto;
export type GetBankFileApiArg = {
  bankFileId: number;
};
export type GetUploadUrlApiResponse =
  /** status 200 Successful Response */ BankFileUploadUrldto;
export type GetUploadUrlApiArg = {
  bankFileId: number;
};
export type ValidateBankFileApiArg = File;
export interface ValidateBankFileApiResponse {
  valid: boolean;
  errors: string[];
}
export type CreateCashBalanceInquiryInvestigationApiResponse =
  /** status 201 Successful Response */ CreateCashBalanceInquiryInvestigationResultDto;
export type CreateCashBalanceInquiryInvestigationApiArg = {
  createCashBalanceInquiryInvestigationDto: CreateCashBalanceInquiryInvestigationDto;
};
export type ListCashBalanceInquiryInvestigationApiResponse =
  /** status 200 Successful Response */ CashBalanceInquiryInvestigationDto[];
export type ListCashBalanceInquiryInvestigationApiArg = {
  entityId?: number | null;
  bankFileId?: number | null;
};
export type GetCashBalanceInquiryInvestigationApiResponse =
  /** status 200 Successful Response */ CashBalanceInquiryInvestigationDto;
export type GetCashBalanceInquiryInvestigationApiArg = {
  cashBalanceInquiryInvestigationId: number;
};
export type GetCashBalanceInquiryInvestigationResultApiResponse =
  /** status 200 Successful Response */ CashBalanceInquiryInvestigationResultDto | null;
export type GetCashBalanceInquiryInvestigationResultApiArg = {
  cashBalanceInquiryInvestigationId: number;
};
export type ResolveCashBalanceInquiryInvestigationApiResponse =
  /** status 200 Successful Response */ any;
export type ResolveCashBalanceInquiryInvestigationApiArg = {
  cashBalanceInquiryInvestigationId: number;
};
export type CancelCashBalanceInquiryInvestigationApiResponse =
  /** status 200 Successful Response */ any;
export type CancelCashBalanceInquiryInvestigationApiArg = {
  cashBalanceInquiryInvestigationId: number;
};
export type BankFileCreatedDto = {
  id: number;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type CreateBankFileDto = {
  entity_id: number;
  bank_account_name: string;
  name: string;
};
export type BankFileDto = {
  id: number;
  name: string;
  entity_id: number;
  bank_account_name: string;
  created_at: string;
  has_file: boolean;
};
export type BankFileUploadUrldto = {
  id: number;
  upload_url: string;
};
export type CreateCashBalanceInquiryInvestigationResultDto = {
  id: number;
};
export type CreateCashBalanceInquiryInvestigationDto = {
  bank_file_id: number;
  max_offset: number;
};
export type CashBalanceInquiryInvestigationStatus =
  | "CREATED"
  | "QUEUED"
  | "RUNNING"
  | "SUCCESS"
  | "ERROR"
  | "APPLIED"
  | "CANCEL";
export type CashBalanceInquiryInvestigationDto = {
  id: number;
  bank_file_id: number;
  entity_id: number;
  max_offset: number;
  created_at: string;
  status: CashBalanceInquiryInvestigationStatus;
  bank_file_name: string;
  bank_account_name: string;
  error: string | null;
};
export type TransactionToDeleteDto = {
  id: number;
  payment_date: string;
  amount: number;
  name: string;
};
export type TransactionToUpdateDto = {
  id: number;
  original_payment_date: string;
  updated_payment_date: string;
  amount: number;
  agicap_name: string;
  bank_name: string;
};
export type TransactionToCreateDto = {
  payment_date: string;
  amount: number;
  name: string;
};
export type DailyInvestigationActionEnrichedDto = {
  day: string;
  transactions_to_delete: TransactionToDeleteDto[];
  transactions_to_update: TransactionToUpdateDto[];
  transactions_to_create: TransactionToCreateDto[];
  original_start_amount: number;
  original_end_amount: number;
  corrected_start_amount: number;
  corrected_end_amount: number;
  has_action: boolean;
};
export type CashBalanceInquiryInvestigationResultDto = {
  to_create_count: number;
  to_delete_count: number;
  to_update_count: number;
  days: DailyInvestigationActionEnrichedDto[];
  bank_account_id: number;
};
export const {
  useCreateBankFileMutation,
  useListBankFileQuery,
  useGetBankFileQuery,
  useValidateBankFileMutation,
  useGetUploadUrlQuery,
  useCreateCashBalanceInquiryInvestigationMutation,
  useListCashBalanceInquiryInvestigationQuery,
  useGetCashBalanceInquiryInvestigationQuery,
  useGetCashBalanceInquiryInvestigationResultQuery,
  useResolveCashBalanceInquiryInvestigationMutation,
  useCancelCashBalanceInquiryInvestigationMutation,
} = injectedRtkApi;
