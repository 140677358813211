import React from "react";
import InvestigationListItem from "./InvestigationListItem";
import {CashBalanceInquiryInvestigationDto} from "../../../services/fbiAPI/fbiApiClient";
import {useListCashBalanceInquiryInvestigationQuery} from "../../../services/fbiAPI";
import List from "@mui/material/List";

const InvestigationListWithData: React.FC<{
    investigations: Array<CashBalanceInquiryInvestigationDto>,
    setActiveInvestigation: (investigationId: number) => void

}> = ({
          investigations,
          setActiveInvestigation,
      }) => {
    if (investigations.length === 0) {
        return <div>No investigations</div>
    } else {
        return (
            <List sx={{
                width: '100%',
                bgColor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: '100%',
            }}>
                {
                    investigations.map((investigation) => {
                        return <InvestigationListItem
                            key={investigation.id} investigation={investigation}
                            onClick={() => setActiveInvestigation(investigation.id)}
                        />;
                    })
                }
            </List>
        );

    }
}
const InvestigationList: React.FC<{
    bankFileId?: number,
    entityId?: number,
    setActiveInvestigation: (investigationId: number) => void
}> = ({bankFileId, entityId, setActiveInvestigation}) => {
    const investigations = useListCashBalanceInquiryInvestigationQuery({bankFileId, entityId});
    if (investigations.isLoading) {
        return <div>Loading</div>
    } else if (investigations.isError || investigations.data === undefined) {
        return <div>Error</div>
    } else {
        return <InvestigationListWithData
            investigations={investigations.data}
            setActiveInvestigation={setActiveInvestigation}
        />
    }
}
export default InvestigationList
