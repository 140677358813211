import {Button, CardContent, CircularProgress, Divider, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import React, {useMemo, useState} from "react";
import {
    CashBalanceInquiryInvestigationDto,
    CashBalanceInquiryInvestigationResultDto,
    DailyInvestigationActionEnrichedDto,
    TransactionToCreateDto,
    TransactionToDeleteDto,
    TransactionToUpdateDto,
} from "../../../services/fbiAPI/fbiApiClient";
import {
    useGetCashBalanceInquiryInvestigationQuery,
    useCancelCashBalanceInquiryInvestigationMutation,
    useGetCashBalanceInquiryInvestigationResultQuery,
    useResolveCashBalanceInquiryInvestigationMutation,
} from "../../../services/fbiAPI";


const Counter: React.FC<{ text: string, count: number }> = ({text, count}) => {
    return (
        <Card>
            <CardContent>
                <Typography
                    component="div"
                    variant="h2"
                >
                    {count}
                </Typography>
                <Typography
                    component="div"
                    color="text.secondary"
                    variant="subtitle1"
                >
                    {text}
                </Typography>
            </CardContent>
        </Card>
    )

}

const COLUMNS = [
    {field: "id", headerName: "id"},
    {field: "date", headerName: "date", type: "date"},
    {field: "original_start_amount", headerName: "original_start_amount"},
    {field: "original_end_amount", headerName: "original_end_amount"},
    {field: "corrected_start_amount", headerName: "corrected_start_amount"},
    {field: "corrected_end_amount", headerName: "corrected_end_amount"},
    {
        field: "has_action", headerName: "has_action", type: 'boolean',
    },
    {field: "transactions_to_delete", headerName: "to delete", type: "number"},
    {field: "transactions_to_create", headerName: "to create", type: "number"},
    {field: "transactions_to_update", headerName: "to update", type: "number"},

]
const InvestigationGrid: React.FC<{
    setSelectedRow: (rowNumber: number | undefined) => void,
    investigationResult: Array<DailyInvestigationActionEnrichedDto>,
}> = ({setSelectedRow, investigationResult}) => {

    const rows = useMemo(() => (
            investigationResult
                .map((t, i) => ({

                    id: i,
                    date: new Date(t.day),
                    original_start_amount: t.original_start_amount,
                    original_end_amount: t.original_end_amount,
                    corrected_start_amount: t.corrected_start_amount,
                    corrected_end_amount: t.corrected_end_amount,
                    has_action: t.has_action,
                    transactions_to_delete: t.transactions_to_delete.length || 0,
                    transactions_to_update: t.transactions_to_update.length || 0,
                    transactions_to_create: t.transactions_to_create.length || 0,
                }))
        ), [investigationResult]
    )

    return <DataGrid
        slots={{toolbar: GridToolbar}}
        style={{height: 400}}
        initialState={{
            filter: {
                filterModel: {
                    items: [{field: 'has_action', operator: 'is', value: 'true'}],
                },
            },
        }}
        rows={rows}
        columns={COLUMNS}
        onRowSelectionModelChange={(e) => setSelectedRow(Number(e[0]))}
    />
}

const TransactionToDelete: React.FC<{ transactions: Array<TransactionToDeleteDto> }> = ({transactions}) => {
    return (
        <Card>
            <CardContent>
                {
                    transactions.length > 0 ? transactions.map((t) => (<div>{t.amount} {t.name}</div>)) : <></>
                }

            </CardContent>
        </Card>
    )

}
const TransactionToUpdate: React.FC<{ transactions: Array<TransactionToUpdateDto> }> = ({transactions}) => {
    return (
        <Card>
            <CardContent>
                {
                    transactions.length > 0 ? transactions.map((t) => (<div>{t.id}</div>)) : <></>
                }
            </CardContent>
        </Card>
    )

}

const TransactionToCreate: React.FC<{ transactions: Array<TransactionToCreateDto> }> = ({transactions}) => {
    return (
        <Card>
            <CardContent>

                {
                    transactions.length > 0 ? transactions.map((t) => (<div>{t.amount} {t.name}</div>)) : <></>
                }
            </CardContent>
        </Card>
    )

}

const CancelResultButton: React.FC<{ investigation: CashBalanceInquiryInvestigationDto }> = ({investigation}) => {
    const [trigger, data] = useCancelCashBalanceInquiryInvestigationMutation();
    if (investigation.status !== "SUCCESS") {
        return <div>ALREADY APPLY</div>
    } else if (data.isLoading) {
        return <>Loading</>
    } else if (data.isSuccess) {
        return <div>SUCCESS</div>
    } else if (data.isError) {
        return <div>Error</div>
    } else {
        const apply = () => {
            trigger({cashBalanceInquiryInvestigationId: investigation.id})
        }
        return (

            <Button
                variant="contained"
                color="error"
                sx={{fontSize: "medium", fontWeight: "600"}}
                onClick={apply}
                disabled={investigation.status !== "SUCCESS"}
            >
                Cancel
            </Button>
        )
    }
}

const ApplyResultButton: React.FC<{
    investigation: CashBalanceInquiryInvestigationDto
    alert: boolean
}> = ({investigation, alert}) => {
    const [trigger, data] = useResolveCashBalanceInquiryInvestigationMutation();
    if (investigation.status !== "SUCCESS") {
        return <div>ALREADY APPLY</div>
    } else if (data.isLoading) {
        return <>Loading</>
    } else if (data.isSuccess) {
        return <div>SUCCESS</div>
    } else if (data.isError) {
        return <div>Error</div>
    } else {
        // const totalNumber = investigation.days.map
        const apply = () => {
            trigger({cashBalanceInquiryInvestigationId: investigation.id})
        }
        return (

            <Button
                variant="contained"
                color={alert ? "warning" : "success"}
                sx={{fontSize: "medium", fontWeight: "600"}}
                onClick={apply}
                disabled={investigation.status !== "SUCCESS"}
            >
                Apply{alert && ` Warning: Large Number of modification`}
            </Button>
        )
    }
}

const InvestigationWithData: React.FC<{
    investigation: CashBalanceInquiryInvestigationDto,
    investigationResult: CashBalanceInquiryInvestigationResultDto
}> = ({
          investigation,
          investigationResult: {
              days,
              to_delete_count,
              to_update_count,
              to_create_count,
          }
      }) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | undefined>();
    const selectedRow = selectedRowIndex === undefined ? undefined : days[selectedRowIndex]
    const {status} = investigation
    const numberDaysWithOpenration = useMemo(() => days.reduce(
            (acc, currentValue) => {
                let is_to_count = 0
                if (
                    currentValue?.transactions_to_delete.length > 0
                    || currentValue?.transactions_to_create.length > 0
                    || currentValue?.transactions_to_update.length > 0
                ) {
                    is_to_count = 1
                }
                return acc + is_to_count
            }
            , 0),
        [days]
    )
    const percentOfDayWithAction = numberDaysWithOpenration / days.length

    return (
        <Stack
            sx={{padding: "10px"}}
            direction="row"
            justifyContent="center"
        >
            <Stack
                spacing={{xs: 1, sm: 2}}
                direction="column"
                useFlexGap
                sx={{
                    maxWidth: "1000px",
                    flexGrow: 1
                }}
            >
                <Box>
                    <Card>
                        <Stack
                            direction="column"
                            alignItems="right"
                        >
                            <Box>Status: {status}</Box>
                            <Box>Bank File: {investigation.bank_file_name}</Box>
                            <Box>Bank Account: {investigation.bank_account_name}</Box>

                        </Stack>
                        {
                            investigation.status === "SUCCESS" &&
                            <CardContent>
                                <Divider sx={{marginBottom: "10px"}}/>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    gap="8px"
                                >
                                        <ApplyResultButton investigation={investigation} alert={percentOfDayWithAction > 0.2}/>
                                        <CancelResultButton investigation={investigation}/>
                                </Stack>
                            </CardContent>
                        }
                    </Card>
                </Box>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    useFlexGap
                >
                    <Counter
                        text="transaction(s) to delete"
                        count={to_delete_count || 0}
                    />
                    <Counter
                        text="transaction(s) to update"
                        count={to_update_count || 0}
                    />
                    <Counter
                        text="transaction(s) to create"
                        count={to_create_count || 0}
                    />
                </Stack>
                <Card>
                    {/*
                    <CardContent>
                        <InvestigationCashBalanceChart/>
                    </CardContent>
                    */}
                </Card>
                <Stack>
                </Stack>
                <Stack>
                    <Card>
                        <InvestigationGrid
                            setSelectedRow={setSelectedRowIndex}
                            investigationResult={days}
                        />
                    </Card>
                </Stack>
                {selectedRow && <TransactionToDelete transactions={selectedRow.transactions_to_delete || []}/>}
                {selectedRow && <TransactionToCreate transactions={selectedRow?.transactions_to_create || []}/>}
                {selectedRow && <TransactionToUpdate transactions={selectedRow?.transactions_to_update || []}/>}

            </Stack>
        </Stack>
    );
};
export const InvestigationNew: React.FC<{ investigationId: number }> = ({investigationId}) => {
    const investigationQuery = useGetCashBalanceInquiryInvestigationQuery({cashBalanceInquiryInvestigationId: investigationId});
    const investigationResultQuery = useGetCashBalanceInquiryInvestigationResultQuery({cashBalanceInquiryInvestigationId: investigationId});
    if (investigationQuery.isLoading || investigationResultQuery.isLoading) {
        return <CircularProgress/>
    } else if (
        investigationQuery.isError
        || investigationQuery.data === undefined
        || investigationResultQuery.isError
        || investigationResultQuery.data === undefined
        || investigationResultQuery.data === null
    ) {
        return (
            <div>
              Error : {investigationQuery.data?.error ?? "Unknown error"}
            </div>
        );
    } else {
        return <InvestigationWithData
            investigation={investigationQuery.data}
            investigationResult={investigationResultQuery.data}
        />
    }
}
