import {Navigate, useNavigate, useParams} from "react-router-dom";
import InvestigationList from "./components/InvestigationList";
import CreateInvestigation from "./components/CreateInvestigation";
import {useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {InvestigationNew} from "./components/InvestigationNew";

const Entity: React.FC<{ entityId: number }> = ({entityId}) => {
    const navigate = useNavigate()
    const [investigationId, setInvestigationId] = useState<number | undefined>();
    const [isInvestigationModalOpen, setIsInvestigationModalOpen] = useState(false);
    return (
        <>
            <CreateInvestigation
                entityId={entityId}
                close={() => setIsInvestigationModalOpen(false)}
                isOpen={isInvestigationModalOpen}
            />

            <Box sx={{height: "100vh", overflow: "hidden"}}>
                <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                    <Box
                        sx={{
                            flexShrink: 1,
                            flowGrowth: 1,
                            flex: "none",
                            paddingTop: "20px",
                            paddingBottom: "15px",
                            paddingLeft: "10px",
                            paddingRight: "30px",
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "8px",
                        }}
                    >
                        {/*  <BankFileList entityId={entityId}/>*/}
                        <Button
                            variant="contained"
                            color="success"
                            sx={{fontSize: "medium", fontWeight: "600"}}
                            onClick={() => (navigate(`/entity/${entityId}/create-bank-file`))}
                        >
                            <AddIcon/>
                            Create Bank file
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{fontSize: "medium", fontWeight: "600"}}
                            onClick={() => (setIsInvestigationModalOpen(true))}
                        >
                            <AddIcon/>
                            Start Investigation
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flexShrink: 1,
                            flowGrowth: 1,
                            flex: "none",
                            display: "flex",
                            justifyContent: "space-between",
                            height: "100%",
                            overflow: "scroll",
                            borderTop: 1,
                            borderColor: "grey.300"
                        }}
                    >
                        <Box
                            sx={{
                                width: "400px",
                                flexShrink: 0,
                                flexGrow: 0,
                                flexBasis: "none",
                                borderRight: 1,
                                borderColor: "grey.300"

                            }}
                        >
                            <InvestigationList
                                setActiveInvestigation={setInvestigationId}
                                entityId={entityId}
                            />

                        </Box>
                        <Box
                            sx={{
                                flexShrink: 1,
                                flexGrow: 1,
                                flexBasis: "none",
                                backgroundColor: "rgb(249, 249, 251)",
                            }}
                        >
                            {investigationId &&
                                <InvestigationNew key={investigationId} investigationId={investigationId}/>}

                        </Box>
                        {/*
                    <div style={{maxWidth: "400px", minWidth: "300px"}}>
                        <div>

                            <div>----------------</div>
                            <div>Start An investigation</div>
                            <CreateInvestigation entityId={entityId}/>
                        </div>
                        <div>

                            <div>----------------</div>
                            <div><h2>Investigations</h2></div>
                            <InvestigationList entityId={entityId} setActiveInvestigation={setInvestigationId}/>
                        </div>

                    </div>
                    <Investigation investigationId={investigationId}/>
            */}
                    </Box>
                </Box>
            </Box>
        </>
    );

}

export const EntityPage: React.FC = () => {
    const {entityId} = useParams();
    const entityIdNumber = Number(entityId);

    if (!entityId || isNaN(entityIdNumber)) {
        return <Navigate to={"/"}/>
    } else {
        return <Entity entityId={entityIdNumber}/>
    }
};
