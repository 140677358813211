import { _gatewayBaseApi as api } from "./gatewayBaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEntityInfo: build.query<GetEntityInfoApiResponse, GetEntityInfoApiArg>({
      query: (queryArg) => ({
        url: `/bo/core/${queryArg.entityId}/getEntityInfo`,
      }),
    }),
    searchEntities: build.query<
      SearchEntitiesApiResponse,
      SearchEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/bo/core/entity`,
        params: { name: queryArg.name },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as agicapGatewayApiClient };
export type GetEntityInfoApiResponse =
  /** status 200 Successful Response */ EntityInfo;
export type GetEntityInfoApiArg = {
  /** entity id used for authentication */
  entityId: number;
};
export type SearchEntitiesApiResponse =
  /** status 200 Successful Response */ SearchEntity[];
export type SearchEntitiesApiArg = {
  /** entity name contains */
  name: string;
};
export type EntityInfo = {
  Id: number;
  Nom: string;
  ObjectifBanque: boolean;
  DisplayManualExpectedOptionsForSynchronizedBankAccounts: boolean;
  CountryCode: string;
  TailleEntreprise?: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type SearchEntity = {
  id: number;
  name: string;
  country_code: string;
};
export const { useGetEntityInfoQuery, useSearchEntitiesQuery } = injectedRtkApi;
