import {fbiApiClient} from './fbiApiClient'
export const baseAPI = fbiApiClient.enhanceEndpoints({
    addTagTypes: ["BankFile", "CashBalanceInquiryInvestigation"],
    endpoints: {
        createBankFile: {
            invalidatesTags: ["BankFile"],
        },
        listBankFile: {
            providesTags: ["BankFile"],
        },
        createCashBalanceInquiryInvestigation: {
            invalidatesTags: ["CashBalanceInquiryInvestigation"],
        },
        listCashBalanceInquiryInvestigation: {
            providesTags: ["CashBalanceInquiryInvestigation"],
        },
        getBankFile: {
            providesTags: ["BankFile"],
        },
        cancelCashBalanceInquiryInvestigation: {
            invalidatesTags: ["CashBalanceInquiryInvestigation"]
        },
        resolveCashBalanceInquiryInvestigation: {
            invalidatesTags: ["CashBalanceInquiryInvestigation"]
        }
    }
})



export const {
    useCreateBankFileMutation,
    useGetCashBalanceInquiryInvestigationQuery,
    useListBankFileQuery,
    useGetUploadUrlQuery,
    useGetBankFileQuery,
    useCreateCashBalanceInquiryInvestigationMutation,
    useListCashBalanceInquiryInvestigationQuery,
    useCancelCashBalanceInquiryInvestigationMutation,
    useGetCashBalanceInquiryInvestigationResultQuery,
    useResolveCashBalanceInquiryInvestigationMutation,
} = baseAPI
