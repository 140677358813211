import React, {useEffect} from 'react';
import './App.css';
import {AuthProvider} from "oidc-react";
import {
    Routes,
    Route,
    useNavigate, useLocation,
} from "react-router-dom";
import './App.css';
import {EntityBankFilePage} from "./pages/entityBankFilePage/EntityBankFilePage";
import {HomePage} from "./pages/homePage/HomePage";
import {CreateBankFilePage} from "./pages/createBankFilePage/CreateBankFilePage";
import {UploadBankFilePage} from './pages/uploadBankFilePage/UploadBankFilePage';
import {BankFilePage} from "./pages/bankFilePage/BankFilePage";
import {EntityPage} from "./pages/entityPage/EntityPage";
import {Provider} from "react-redux";
import {store} from "./services/store";

const LAST_PATH_KEY = "lastPath"

function App() {
    const navigate = useNavigate();
    const location = useLocation()
    let lastUrlStorage = localStorage.getItem(LAST_PATH_KEY)
    if (lastUrlStorage === undefined || lastUrlStorage === null) {
        lastUrlStorage = "/"
    }

    useEffect(
        () => {
            if (location.pathname !== "/auth/callback") {
                localStorage.setItem(LAST_PATH_KEY, location.pathname);
            }
        }
        , [location.pathname]
    )


    return (
        <div className="App">
            <AuthProvider
                autoSignIn={true}
                responseType="code"
                automaticSilentRenew={true}
                authority={process.env.REACT_APP_OIDC_AUTHORITY || "https://preprod-myaccount.agicap.cloud"}
                redirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                popupRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                silentRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                clientId={process.env.REACT_APP_OIDC_CLIENT_ID || "agisight"}
                scope={process.env.REACT_APP_OIDC_SCOPE || "agicap:app openid profile"}
                onSignIn={(user) => {
                    // @ts-ignore
                    navigate(lastUrlStorage)
                }}
            >
                <Provider store={store}>

                        <Routes>
                            <Route path="/bank-file/:bankFileId" element={<BankFilePage/>}/>
                            <Route path="/entity/:entityId" element={<EntityPage/>}/>
                            <Route path="/entity/:entityId/bank-file/:bankFileId/upload"
                                   element={<UploadBankFilePage/>}/>
                            <Route path="/entity/:entityId/create-bank-file" element={<CreateBankFilePage/>}/>
                            <Route path="/entity/:entityId/bank-files" element={<EntityBankFilePage/>}/>
                            <Route path="*" element={<HomePage/>}/>
                        </Routes>
                </Provider>
            </AuthProvider>
        </div>
    );
}

export default App;
