import React from 'react';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {
    BankFileDto,
    useListBankFileQuery,
} from '../../services/fbiAPI/fbiApiClient';


const BankFile: React.FC<{ bankFile: BankFileDto }> = ({bankFile}) => {
    return <div key={bankFile.id}> <Link to={`/bank-file/${bankFile.id}`}>{bankFile.name}</Link></div>
}

const EntityBankFilePageWithBankFile: React.FC<{
    bankFiles: Array<BankFileDto>,
    entityId: number,
}> = ({
          bankFiles,
          entityId,
      }) => {
    const navigate = useNavigate();

    return (
        <div>
            <div>

                {bankFiles.map((bankFile) => <BankFile bankFile={bankFile} key={bankFile.id}/>)}
            </div>
            <button onClick={() => navigate(`/entity/${entityId}/create-bank-file`)}> Create Bank File</button>
        </div>
    )


}

const EntityBankFilePageWithEntityId: React.FC<{ entityId: number }> = ({entityId}) => {
    const listBankFile = useListBankFileQuery({entityId: entityId});
    if (listBankFile.isLoading) {
        return <div>Loading</div>
    } else if (listBankFile.isError) {
        return <div>Error</div>
    } else {
        return <EntityBankFilePageWithBankFile entityId={entityId} bankFiles={listBankFile.data || []}/>
    }
}


export const EntityBankFilePage: React.FC = () => {
    const {entityId} = useParams();
    const entityIdNumber = Number(entityId);

    if (!entityId || isNaN(entityIdNumber)) {
        return <Navigate to={"/"}/>
    }


    return <EntityBankFilePageWithEntityId entityId={entityIdNumber}/>

}
