import {agicapGatewayApiClient} from './agicapGatewayClient'

export const gatewayBaseAPI = agicapGatewayApiClient.enhanceEndpoints({
    addTagTypes: ["SearchEntity", "EntityInfo"],
    endpoints: {
        searchEntities: {
            providesTags: ["SearchEntity"]
        },
        getEntityInfo: {
            providesTags: ["EntityInfo"]
        },
    }
})


export const {
    useGetEntityInfoQuery,
    useSearchEntitiesQuery,
} = gatewayBaseAPI
