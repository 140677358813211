import React from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {CreateBankFileDto} from "../../services/fbiAPI/fbiApiClient";
import { useCreateBankFileMutation} from "../../services/fbiAPI";


const useCreateBankFile: (entityId: number) => {
    isValid: boolean,
    name: string,
    bankAccountName: string,
    create: () => void,
    setName: (name: string) => void,
    setBankAccountName: (bankAccountName: string) => void,
    isLoading: boolean,
    isError: boolean,
    isSuccess: boolean,
    bankFileId?: number,
    error?: string,
} = (entityId) => {
    const [createBankFile, createBankFileResult] = useCreateBankFileMutation();
    const [name, setName] = React.useState<string>("");
    const [bankAccountName, setBankAccountName] = React.useState<string>("");

    const isValid = name.length > 0 && bankAccountName.length > 0



    return {
        isLoading: createBankFileResult.isLoading,
        isError: createBankFileResult.isError,
        isSuccess: createBankFileResult.isSuccess,
        isValid: isValid,
        error: createBankFileResult.error && createBankFileResult.error.toString(),
        name: name,
        bankAccountName: bankAccountName,
        setBankAccountName: setBankAccountName,
        setName: setName,
        bankFileId: createBankFileResult.data?.id,
        create: async () => {
            const createBankFileDto: CreateBankFileDto = {
                entity_id: entityId,
                name: name,
                bank_account_name: bankAccountName,
            }
            createBankFile({createBankFileDto});
        }
    }
}
export const CreateBankFile: React.FC<{ entityId: number }> = ({entityId}) => {
    const {
        isValid,
        name,
        bankAccountName,
        setBankAccountName,
        setName,
        create,
        isSuccess,
        isLoading,
        isError,
        error,
        bankFileId,
    } = useCreateBankFile(entityId);
    const navigate = useNavigate();
    if (isLoading) {
        return <div>Loading</div>
    } else if (isError || (isSuccess && bankFileId === undefined)) {
        return <div>{error}</div>
    } else if (isSuccess) {
        return <Navigate to={`/entity/${entityId}/bank-file/${bankFileId}/upload`}/>
    } else {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                create();
            }}>
                <label htmlFor="fname">Name:</label><br/>
                <input type="text" id="fname" name="fname" value={name} onChange={(e) => setName(e.target.value)}/><br/>
                <label htmlFor="lname">Bank account name:</label><br/>
                <input type="text" id="lname" name="lname" value={bankAccountName}
                       onChange={(e) => setBankAccountName(e.target.value)}
                /><br/>
                <button type="submit" disabled={!isValid}>Validate</button>
                <button type="button" onClick={() => navigate(`/entity/${entityId}`)}>Cancel</button>
            </form>
        )
    }

};

export const CreateBankFilePage: React.FC = () => {
    const {entityId} = useParams();
    const entityIdNumber = Number(entityId);

    if (!entityId || isNaN(entityIdNumber)) {
        return <Navigate to={"/"}/>
    }


    return <CreateBankFile entityId={entityIdNumber}/>

}
