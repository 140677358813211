import {Navigate, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {useGetUploadUrlQuery} from "../../services/fbiAPI";
import {useUploadFileToURL} from "../../hooks/uploadFile";

const getFileFromEvent: (e: React.ChangeEvent<HTMLInputElement>) => File | undefined = (e) => {
    if (e.target.files) {
        return e.target.files[0];
    }
    return undefined;
}
const UploadBankFileToUrl: React.FC<{
    bankFileId: number,
    entityId: number,
    uploadUrl: string
}> = (
    {
        bankFileId,
        entityId,
        uploadUrl
    }
) => {
    const {
        isSuccess,
        isLoading,
        isError,
        errorMessage,
        isValid,
        setFile,
        upload,
    } = useUploadFileToURL(bankFileId, uploadUrl);
    const navigate = useNavigate();
    if (isSuccess) {
        return <Navigate to={`/entity/${entityId}`}/>
    } else if (isError) {
        return <div>{errorMessage}</div>
    } else if (isLoading) {
        return <div>Loading</div>
    }

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            await upload();
        }}>
            <label htmlFor="lfile">File:</label><br/>
            <input
                type="file"
                id="lfile"
                name="lfile"
                accept=".xlsx"
                onChange={(e) => {
                    setFile(getFileFromEvent(e))
                }}
            /><br/>
            <button type="submit" disabled={!isValid}>Validate</button>
            <button type="button" onClick={() => navigate(`/entity/${entityId}/bank-files`)}>Cancel</button>
        </form>
    );
};

const UploadBankFile: React.FC<{ bankFileId: number, entityId: number }> = ({bankFileId, entityId}) => {
    const { data ,isError, isLoading} = useGetUploadUrlQuery({bankFileId});
    if (isLoading) {
        return <div>Loading</div>
    } else if (isError || !data) {
        return <div>Error</div>
    } else {
        return <UploadBankFileToUrl
            bankFileId={bankFileId}
            entityId={entityId}
            uploadUrl={data.upload_url}
        />
    }
}

export const UploadBankFilePage = () => {
    const {bankFileId} = useParams();
    const {entityId} = useParams();
    const bankFileIdNumber = Number(bankFileId);

    if (!bankFileId || isNaN(bankFileIdNumber)) {
        return <Navigate to={"/"}/>
    }
    const entityIdNumber = Number(entityId);

    if (!entityId || isNaN(entityIdNumber)) {
        return <Navigate to={"/"}/>
    }

    return <UploadBankFile
        bankFileId={bankFileIdNumber}
        entityId={entityIdNumber}
    />


}
