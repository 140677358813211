import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useCreateCashBalanceInquiryInvestigationMutation, useListBankFileQuery} from "../../../services/fbiAPI";
import {BankFileDto, CreateCashBalanceInquiryInvestigationDto} from "../../../services/fbiAPI/fbiApiClient";

const CreateInvestigationWithBankFileList: React.FC<{
    bankFiles: Array<BankFileDto>,
    defaultOffset?: number,
    close: () => void,
}> = ({bankFiles, defaultOffset, close}) => {
    const [startInvestigation, startInvestigationResult] = useCreateCashBalanceInquiryInvestigationMutation()
    const [maxOffset, setMaxOffset] = useState<number | undefined>(defaultOffset)
    const [bankFileId, setBankFileId] = useState<number | undefined>(undefined)
    const onSubmit = () => {
        if (maxOffset !== undefined && bankFileId !== undefined) {
            const createCashBalanceInquiryInvestigationDto: CreateCashBalanceInquiryInvestigationDto = {
                bank_file_id: bankFileId,
                max_offset: maxOffset,
            }

            startInvestigation({createCashBalanceInquiryInvestigationDto})
        }
    }
    useEffect(() => {
        if (startInvestigationResult.isSuccess) {
            close()
        }
    }, [startInvestigationResult.isSuccess, close])

    if (startInvestigationResult.isLoading) {
        return <div>Starting investigation</div>
    } else if (startInvestigationResult.isError) {
        return <div>Error starting investigation</div>
    } else {
        const updateOffset = (e: React.ChangeEvent<HTMLInputElement>) => {
            const tmp = Number(e.target.value)
            if (!isNaN(tmp) && tmp > 0 && tmp < 8) {
                setMaxOffset(tmp)
            } else {
                setMaxOffset(undefined)
            }
        }
        const updateBankFileId = (e: SelectChangeEvent) => {
            e.preventDefault()
            const tmp = Number(e.target.value)
            if (!isNaN(tmp)) {
                setBankFileId(tmp)
            } else {
                setBankFileId(undefined)
            }
        }
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Bank File</InputLabel>
                    <Select
                        labelId="select-bank-file"
                        id="select-bank-file"
                        value={bankFileId?.toString() || ""}
                        label="bank-file"
                        onChange={updateBankFileId}
                    >
                        {
                            bankFiles.map((bankFile) => {
                                return (
                                    <MenuItem value={bankFile.id} key={bankFile.id} disabled={!bankFile.has_file}>
                                        {bankFile.name} {bankFile.has_file ? "" : "(No File Associated)"}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <TextField
                    id="outlined-number"
                    label="Number"
                    type="number"
                    onChange={updateOffset}
                    InputProps={{inputProps: {min: 0, max: 10}}}
                />


                <DialogActions>
                    <Button onClick={close}>Cancel</Button>
                    <Button onClick={onSubmit}
                            disabled={maxOffset === undefined || bankFileId === undefined}>Create</Button>
                </DialogActions>
            </>
        );
    }
};
const CreateInvestigationContent: React.FC<{
    entityId: number,
    close: () => void,
}> = ({entityId, close}) => {
    const listBankFile = useListBankFileQuery({entityId: entityId});
    if (listBankFile.isLoading) {
        return <CircularProgress/>
    } else if (listBankFile.isError) {
        return <div>Error</div>
    } else {
        let bankWithFiles = [...(listBankFile.data || [])]

        bankWithFiles.sort((a: BankFileDto, b: BankFileDto): number => {
            return Number(a.has_file) > Number(b.has_file) ? -1 : 1
        })
        return <CreateInvestigationWithBankFileList bankFiles={bankWithFiles} close={close}/>
    }
}

const CreateInvestigation: React.FC<{
    entityId: number,
    isOpen: boolean,
    close: () => void,
}> = ({entityId, close, isOpen}) => {
    return (

            <Dialog onClose={close} open={isOpen}>
                <DialogTitle>Start Investigation</DialogTitle>
                <DialogContent>
                    <CreateInvestigationContent entityId={entityId} close={close}/>
                </DialogContent>
            </Dialog>
    )
}

export default CreateInvestigation;
