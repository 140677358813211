import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSearchEntitiesQuery} from "../../services/agicapGatewayAPI";

type EntityListProps = {
    searchTerm: string;
};
const EntityList: React.FC<EntityListProps> = (
    {
        searchTerm
    }: EntityListProps
) => {
    const [filteredSearchTerm, setFilteredSearchTerm] = useState(searchTerm);
    const {
        data,
        error,
        isLoading,
        isFetching,
    } = useSearchEntitiesQuery(
        {name: filteredSearchTerm}
    );
    const entities = data ?? [];

    useEffect(() => {
        if (searchTerm.length === 0 || searchTerm.length > 2) {
            setFilteredSearchTerm(searchTerm);
        }
    }, [searchTerm]);

    if (error) {
        return <div className="text-hint">Error while fetching entities</div>;
    }

    if (isLoading) {
        return <div className="text-hint">Loading entities...</div>;
    }

    if (isFetching) {
        return <div className="text-hint">Fetching entities...</div>;
    }

    if (entities.length === 0) {
        return <div className="text-hint">No entity found</div>;
    }

    return (
        <ul>
            {entities.map(({id, name}) => (
                <li key={id}>
                    <Link to={`/entity/${id}`}>
                        {name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

function useDebounce(value: string, delay: number): string {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export const HomePage: React.FC = () => {
    const [name, setName] = useState<string>("");
    const debouncedName = useDebounce(name, 500);
    return (
        <div>
            <input
                className="search-input"
                type="text"
                placeholder="Entity Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <EntityList searchTerm={debouncedName}/>
        </div>
    );
};
