import {Navigate, useParams} from "react-router-dom";
import {
    useCreateCashBalanceInquiryInvestigationMutation,
    useGetBankFileQuery,
    useListCashBalanceInquiryInvestigationQuery
} from "../../services/fbiAPI";
import {
    BankFileDto,
    CashBalanceInquiryInvestigationDto,
    CreateCashBalanceInquiryInvestigationDto,
} from "../../services/fbiAPI/fbiApiClient";
import {useState} from "react";

const BankFilePageWithBankFile: React.FC<{ bankFile: BankFileDto }> = ({bankFile}) => {
    return (
        <div>
            <div><span>Name: </span><span>{bankFile.name}</span></div>
            <div><span>Bank Account Name: </span><span>{bankFile.bank_account_name}</span></div>
            <div><span>Has file:</span><span>{bankFile.has_file.toString()}</span></div>
        </div>);
}


const BankFilePageWithBankFileId: React.FC<{ bankFileId: number }> = ({bankFileId}) => {
    const bankFile = useGetBankFileQuery({bankFileId});
    if (bankFile.isLoading) {
        return <div>Loading</div>
    } else if (bankFile.isError || bankFile.data === undefined) {
        return <div>Error</div>
    } else {
        return <BankFilePageWithBankFile bankFile={bankFile.data}/>
    }
}

const Investigation: React.FC<{ investigation: CashBalanceInquiryInvestigationDto }> = ({investigation}) => {
    return (<div key={investigation.id}>{JSON.stringify(investigation)}</div>)
}
const InvestigationList: React.FC<{
    investigations: Array<CashBalanceInquiryInvestigationDto>
}> = ({
          investigations
      }) => {
    if (investigations.length === 0) {
        return <div>No investigations</div>
    } else {
        return (
            <div>
                {
                    investigations.map((investigation) => {
                        return (<Investigation key={investigation.id} investigation={investigation}/>)
                    })
                }
            </div>
        );

    }
}

const InvestigationWithBankId: React.FC<{ bankFileId: number }> = ({bankFileId}) => {
    const investigations = useListCashBalanceInquiryInvestigationQuery({bankFileId});
    if (investigations.isLoading) {
        return <div>Loading</div>
    } else if (investigations.isError || investigations.data === undefined) {
        return <div>Error</div>
    } else {
        return <InvestigationList investigations={investigations.data}/>
    }

}

const StartInvestigation: React.FC<{ bankFileId: number }> = ({bankFileId}) => {
    const [startInvestigation, startInvestigationResult] = useCreateCashBalanceInquiryInvestigationMutation()
    const [maxOffset, setMaxOffset] = useState<number | undefined>(undefined)
    const onSubmit = () => {
        if (maxOffset !== undefined) {

            const createCashBalanceInquiryInvestigationDto: CreateCashBalanceInquiryInvestigationDto = {
                bank_file_id: bankFileId,
                max_offset: maxOffset,
            }

            startInvestigation({createCashBalanceInquiryInvestigationDto})
        }
    }

    if (startInvestigationResult.isLoading) {
        return <div>Starting investigation</div>
    } else if (startInvestigationResult.isError) {
        return <div>Error starting investigation</div>
    } else {
        const updateOffset = (e: React.ChangeEvent<HTMLInputElement>) => {
            const tmp = Number(e.target.value)
            if (!isNaN(tmp) && tmp > 0) {
                setMaxOffset(tmp)
            } else {
                setMaxOffset(undefined)
            }
        }
        return (
            <form onSubmit={onSubmit}>
                <label htmlFor="maxOffset">Max Offset:</label><br/>
                <input type="number" id="maxOffset" name="maxOffset"
                       defaultValue={3}
                       onChange={updateOffset}/><br/>
                <button type="submit" disabled={!maxOffset}>Start Investigation</button>
            </form>
        );
    }
}

export const BankFilePage: React.FC = () => {
    const {bankFileId} = useParams();
    const bankFileIdNumber = Number(bankFileId);

    if (!bankFileId || isNaN(bankFileIdNumber)) {
        return <Navigate to={"/"}/>
    } else {
        return <div>
            <BankFilePageWithBankFileId bankFileId={bankFileIdNumber}/>
            <div>-------------------------</div>
            <InvestigationWithBankId bankFileId={bankFileIdNumber}/>
            <div>-------------------------</div>
            <StartInvestigation bankFileId={bankFileIdNumber}/>
        </div>
    }
};
