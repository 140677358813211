import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const TOKEN_SESSION_STORAGE_KEY = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;
export const _baseApi = createApi({
    reducerPath: "apiFBI",
    tagTypes: ["BankFile", "CashBalanceInquiryInvestigation"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL || '/',
        prepareHeaders: (headers, {getState}) => {
            // @ts-ignore
            headers.set("authorization", `Bearer ${JSON.parse(sessionStorage.getItem(TOKEN_SESSION_STORAGE_KEY)).access_token}`);
            return headers;
        }
    }),
    endpoints: () => ({}),
})
